import { Transaction } from "../interfaces/Transaction";
import { Statistic } from "./Statistic";
import './Statistics.css'

interface Props {
    transactions: Transaction[];
    previousTransactions: Transaction[];
    loading: boolean;
    loadingPrevious: boolean;
}

export interface StatisticData {
    label: string;
    current: string;
    difference: null | number;
}

interface Stats {
    numberSales: number;
    totalSales: number;
    totalUsers: number;
}

export function Statistics({ transactions, previousTransactions, loading, loadingPrevious }: Props) {

    function calculateStats(transactions: Transaction[]): Stats {
        let numberSales = transactions.length;
        let totalSales = 0;
        let users = new Set<string>();

        transactions.forEach(transaction => {
            totalSales += transaction.price / 100;
            users.add(transaction.user);
        })
        let totalUsers = users.size;

        return { numberSales, totalSales, totalUsers }
    }
    function difference(now: number, previously: number | null): number | null {
        if (!previously) return null;
        return (Math.round((now / previously) * 10000) / 100) - 100;
    }

    let now = calculateStats(transactions);
    let previously = calculateStats(previousTransactions);

    let numberSalesDiff = difference(now.numberSales, previously.numberSales);
    let totalSalesDiff = difference(now.totalSales, previously.totalSales);
    // let usersDiff = difference(now.totalUsers, previously.totalUsers);


    return <div className="statistics">
        <Statistic loading={loading} loadingPrevious={loadingPrevious} data={{ label: "Intäkter", current: "" + Math.round(now.totalSales), difference: totalSalesDiff }} />
        {/*<Statistic loading={loading} loadingPrevious={loadingPrevious} data={{ label: "Antal användare", current: "" + now.totalUsers, difference: usersDiff }} />*/}
        <Statistic loading={loading} loadingPrevious={loadingPrevious} data={{ label: "Antal försäljningar", current: "" + now.numberSales, difference: numberSalesDiff }} />
    </div>;
}