import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';
// import { getFirestore } from 'firebase/firestore'
import { Transaction } from './interfaces/Transaction';
import { startOfDay, endOfDay } from 'date-fns';
import { DefaultService } from './generated-client';

const firebaseConfig = process.env.NODE_ENV === 'production' ? {
    apiKey: window.config.REACT_APP_API_KEY,
    authDomain: window.config.REACT_APP_AUTH_DOMAIN,
    projectId: window.config.REACT_APP_PROJECT_ID,
    storageBucket: window.config.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: window.config.REACT_APP_MESSAGING_SENDER_ID,
    appId: window.config.REACT_APP_APP_ID
} : {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
    try {
        await signInWithPopup(auth, googleProvider);
    } catch (err) {
        console.error(err);
    }
}

async function sendResetEmail(email: string) {
    await sendPasswordResetEmail(auth, email);
}


async function signInWithEmail(email: string, password: string) {
    await signInWithEmailAndPassword(auth, email, password);
}

const logoutFirebase = () => {
    signOut(auth);
}

async function getTransactions(from: Date, to: Date, partner: string, fullAccess: boolean): Promise<Transaction[]> {
    from = startOfDay(from);
    to = endOfDay(to);
    const transactions = await DefaultService.getTransactions({ startDate: from.getTime(), endDate: to.getTime() }, `Bearer ${await getAuth().currentUser?.getIdToken()}`)

    return transactions.map(t => ({ ...t, date: new Date(t.date) }));
}

export { signInWithEmail, sendResetEmail, logoutFirebase, auth, signInWithGoogle, getTransactions }
